<template>
  <div class="columns is-multiline is-marginless">
    <div class="column is-12">
      <form @submit.prevent="createAccount">
        <hgroup class="has-text-centered has-margin-bottom-150">
          <img
            src="/assets/images/interface/logos/fixed.svg"
            alt="Fixed"
            width="160"
            class="is-hidden-desktop has-margin-bottom-100 has-margin-top-100"
          />

          <h2 class="is-size-2 is-size-3-touch has-margin-bottom-50">
            Get started for free.
          </h2>

          <p>
            Already have an account?
            <router-link :to="{ name: 'login' }">
              <u>Login here</u> </router-link
            >.
          </p>
        </hgroup>

        <div class="columns">
          <div class="column">
            <b-field label="Email">
              <b-input
                v-model="email"
                :disabled="processing"
                type="email"
                autocomplete="email"
                placeholder="Your email"
              />
            </b-field>

            <create-password
              :disabled="processing"
              @validated="password = $event"
            />

            <b-field class="has-text-centered">
              <p class="has-text-grey">
                <small>
                  By clicking "Continue" I agree to Fixed's
                  <a
                    href="https://fixed.net/knowledge-base/article/terms-and-conditions"
                    target="_blank"
                    >Terms of Service</a
                  >.
                </small>
              </p>
            </b-field>

            <b-field class="has-text-centered has-margin-top-150">
              <button
                :class="{ 'is-loading': processing }"
                :disabled="formIsDisabled"
                :style="{ minWidth: '15rem' }"
                type="submit"
                class="button is-primary is-rounded is-medium"
              >
                <strong class="is-size-6">Continue</strong>
              </button>
            </b-field>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Register",
  components: {
    "create-password": () => import("@shared/account/_createPassword")
  },
  data() {
    return {
      errors: [],
      email: "",
      password: "",
      processing: false
    };
  },
  computed: {
    formIsValid() {
      if (!this.$v.isEmail(this.email)) return false;
      if (!this.password) return false;
      return true;
    },
    formIsDisabled() {
      return this.processing || !this.formIsValid;
    },
    userIsAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"]();
    }
  },
  watch: {
    userIsAuthenticated() {
      if (this.userIsAuthenticated) {
        this.$router.push({ path: "/" });
      }
    }
  },
  methods: {
    createAccount() {
      if (this.formIsDisabled) return;
      this.processing = true;
      this.$store
        .dispatch("auth/register", {
          email: this.email,
          password: this.password
        })
        .then(() => {
          this.$toast.open({
            message: "Welcome to fixed",
            position: "is-bottom",
            queue: false
          });
        })
        .catch(error => {
          this.processing = false;
          this.$toast.open({
            message: error,
            position: "is-bottom",
            queue: false,
            type: "is-danger"
          });
        });
    }
  }
};
</script>
